import React, { Component } from "react";
import logo from "./logo.svg";
import Button from 'react-bootstrap/Button';
import "./App.css";
import ReactCanvasConfetti from "react-canvas-confetti";

function randomInRange(min, max) {
  return Math.random() * (max - min) + min;
}

const canvasStyles = {
  position: "fixed",
  pointerEvents: "none",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  drift: 1000,
  scalar: 5,
  gravity: 2
};

class App extends Component {
  constructor(props) {
      super(props);
      this.isAnimationEnabled = false;
      this.animationInstance = null;
      this.intervalId = null;
      this.state = { dollars: 0 };
  }

  makeShot = (angle, originX) => {
    this.animationInstance &&
      this.animationInstance({
        particleCount: 2,
        angle,
        spread: 100,
        origin: { x: originX },
        colors: ["#118c4f", "#299861"],
      });
  };
  

  componentWillUnmount() {
    this.isAnimationEnabled = false;
    this.intervalId && clearInterval(this.intervalId);
  }

  nextTickAnimation = () => {
    this.makeShot(60, 0);
    this.makeShot(120, 1);
    this.setState((prevState, props) => ({
      dollars: prevState.dollars + 2.
    }));
    if (this.isAnimationEnabled) requestAnimationFrame(this.nextTickAnimation);
  };

  toggleAnimation = () => {
    if (!this.isAnimationEnabled) {
      this.isAnimationEnabled = true;
      this.intervalId = setInterval(this.nextTickAnimation, 400);
    } else {
      this.isAnimationEnabled = false;
      return this.intervalId && clearInterval(this.intervalId);
    }
  };

  getInstance = (instance) => {
    this.animationInstance = instance;
  };

  render() {
    return (
      <div className="App">
        <header className="App-header">
          {/* <img src={logo} className="App-logo" alt="logo" /> */}
          <p>
            Cocobruh
          </p>
          <Button onClick={this.toggleAnimation}>
            Click me
          </Button>
          <p>${this.state.dollars}</p>
          <ReactCanvasConfetti
            refConfetti={this.getInstance}
            style={canvasStyles}
        /> 
        </header>
      </div>
    );
  }
}
/*
function App() {
    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                <p>Scroll down for more.</p>
            </header>
        </div>
    );
}*/

export default App;
